<template>
  <div>
    <div class="content-tab border-top-line border-bottom">
      <v-container
        fluid
        class="pa-0"
        v-if="!switchEdit"
      >
        <v-row>
          <v-col
            cols="12"
            md="7"
          >
            <div>
              <div class="card-content">
                <div class="display-flex align-items-center border-bottom pa-2">
                  <p class="text-header mon-medium">{{ texts.profile.tab2.list.name }}</p>
                  <v-spacer></v-spacer>
                  <p
                    class="text-result mon-regular"
                    v-html="(enterpriseName == '') ? epmtyText : enterpriseName"
                  />
                </div>
                <div class="display-flex align-items-center border-bottom pa-2">
                  <p class="text-header mon-medium">{{ texts.profile.tab2.list.email }}</p>
                  <v-spacer></v-spacer>
                  <p
                    class="text-result mon-regular"
                    v-html="(enterpriseEmail == '') ? epmtyText : enterpriseEmail"
                  />
                </div>
                <div class="display-flex align-items-center border-bottom pa-2">
                  <p class="text-header mon-medium">{{ texts.profile.tab2.list.phone }}</p>
                  <v-spacer></v-spacer>
                  <!-- <p class="text-result mon-regular">{{ enterprisePhone }}</p> -->
                  <p
                    class="text-result mon-regular"
                    v-html="(enterprisePhone == '') ? epmtyText : enterprisePhone"
                  />
                </div>
                <div class="display-flex align-items-center border-bottom pa-2">
                  <p class="text-header mon-medium">{{ texts.profile.tab2.list.paca }}</p>
                  <v-spacer></v-spacer>
                  <!-- <p class="text-result mon-regular">{{ enterpriseLicencePaca }}</p> -->
                  <p
                    class="text-result mon-regular"
                    v-html="(enterpriseLicencePaca == '') ? epmtyText : enterpriseLicencePaca"
                  />
                </div>
                <div class="display-flex align-items-center border-bottom pa-2">
                  <p class="text-header mon-medium">{{ texts.profile.tab2.list.blueBook }}</p>
                  <v-spacer></v-spacer>
                  <!-- <p class="text-result mon-regular">{{ enterpriseBlueBook }}</p> -->
                  <p
                    class="text-result mon-regular"
                    v-html="(enterpriseBlueBook == '') ? epmtyText : enterpriseBlueBook"
                  />
                </div>
                <div class="display-flex align-items-center border-bottom pa-2">
                  <p class="text-header mon-medium">{{ texts.profile.tab2.list.rfc }}</p>
                  <v-spacer></v-spacer>
                  <!-- <p class="text-result mon-regular">{{ enterpriseRFC }}</p> -->
                  <p
                    class="text-result mon-regular"
                    v-html="(enterpriseRFC == '') ? epmtyText : enterpriseRFC"
                  />
                </div>
                <div class="display-flex align-items-center border-bottom pa-2">
                  <p class="text-header mon-medium">{{ texts.profile.tab2.list.address }}</p>
                  <v-spacer></v-spacer>
                  <!-- <p class="text-result mon-regular">{{ enterpriseAddress }}</p> -->
                  <p
                    class="text-result mon-regular"
                    v-html="(enterpriseAddress == '') ? epmtyText : enterpriseAddress"
                  />
                </div>
                <div class="display-flex align-items-center border-bottom pa-2">
                  <p class="text-header mon-medium">
                    {{ 
                      (selectLanguage == 'sp')
                      ? 'Dirección específica'
                      : 'Especific address'
                    }}
                  </p>
                  <v-spacer></v-spacer>
                  <!-- <p class="text-result mon-regular">{{ enterpriseAddressOptional }}</p> -->
                  <p
                    class="text-result mon-regular"
                    v-html="(enterpriseAddressOptional == '') ? epmtyText : enterpriseAddressOptional"
                  />
                </div>
                <div class="display-flex align-items-center border-bottom pa-2">
                  <p class="text-header mon-medium">{{ texts.profile.tab2.list.city }}</p>
                  <v-spacer></v-spacer>
                  <!-- <p class="text-result mon-regular">{{ enterpriseCity }}</p> -->
                  <p
                    class="text-result mon-regular"
                    v-html="(enterpriseCity == '') ? epmtyText : enterpriseCity"
                  />
                </div>
                <div class="display-flex align-items-center border-bottom pa-2">
                  <p class="text-header mon-medium">{{ texts.profile.tab2.list.zipCode }}</p>
                  <v-spacer></v-spacer>
                  <!-- <p class="text-result mon-regular">{{ enterpriseZipCode }}</p> -->
                  <p
                    class="text-result mon-regular"
                    v-html="(enterpriseZipCode == '') ? epmtyText : enterpriseZipCode"
                  />
                </div>
                <div class="display-flex align-items-center border-bottom pa-2">
                  <p class="text-header mon-medium">{{ texts.profile.tab2.list.country }}</p>
                  <v-spacer></v-spacer>
                  <!-- <p class="text-result mon-regular">{{ enterpriseCountryName }}</p> -->
                  <p
                    class="text-result mon-regular"
                    v-html="(enterpriseCountryName == '') ? epmtyText : enterpriseCountryName"
                  />
                </div>
                <div class="display-flex align-items-center pa-2">
                  <p class="text-header mon-medium">{{ texts.profile.tab2.list.state }}</p>
                  <v-spacer></v-spacer>
                  <p
                    class="text-result mon-regular"
                    v-html="(enterpriseStateName == '') ? epmtyText : enterpriseStateName"
                  />
                </div>
              </div>
            </div>
          </v-col>
          <v-col
            cols="12"
            md="5"
          >
            <div>
              <GmapMap
                :center="center"
                :zoom="4"
                :options="mapOptions"
                style="width: 100%; height: 410px;"
              >
                <GmapMarker
                  :key="index"
                  v-for="(m, index) in markers"
                  :position="m.position"
                  @click="center = m.position"
                />
              </GmapMap>
            </div>
          </v-col>
        </v-row>
      </v-container>
      <v-container
        v-else
        fluid
        class="pa-0"
        style="width: 100%"
      >
        <v-row>
          <v-col
            cols="12"
            md="6"
          >
            <div class="width-100">
              <div class="form">
                <v-row>
                  <v-col
                    cols="12"
                    md="12"
                  >
                    <v-text-field
                      v-model="enterpriseName"
                      type="text"
                      :label="texts.enterpriseData.enterpriseName.label"
                      outlined
                      color="#000000"
                      dense
                      class="global-inputs mon-regular"
                      :placeholder="texts.enterpriseData.enterpriseName.placeholder"
                      persistent-placeholder
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    cols="12"
                    md="7"
                  >
                    <v-text-field
                      v-model="enterpriseEmail"
                      type="text"
                      :label="texts.enterpriseData.enterpriseEmail.label"
                      outlined
                      color="#000000"
                      dense
                      class="global-inputs mon-regular"
                      :placeholder="texts.enterpriseData.enterpriseEmail.placeholder"
                      persistent-placeholder
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    md="5"
                  >
                    <v-text-field
                      v-model="enterprisePhone"
                      type="text"
                      :label="texts.enterpriseData.enterprisePhone.label"
                      outlined
                      color="#000000"
                      dense
                      class="global-inputs mon-regular"
                      :placeholder="texts.enterpriseData.enterprisePhone.placeholder"
                      persistent-placeholder
                      @keypress="soloNumbers"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-text-field
                      v-model="enterpriseLicencePaca"
                      type="text"
                      :label="texts.enterpriseData.licencePaca.label"
                      outlined
                      color="#000000"
                      dense
                      class="global-inputs mon-regular"
                      :placeholder="texts.enterpriseData.licencePaca.placeholder"
                      persistent-placeholder
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-text-field
                      v-model="enterpriseBlueBook"
                      type="text"
                      :label="texts.enterpriseData.blueBook.label"
                      outlined
                      color="#000000"
                      dense
                      class="global-inputs mon-regular"
                      :placeholder="texts.enterpriseData.blueBook.placeholder"
                      persistent-placeholder
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-text-field
                      v-model="enterpriseRFC"
                      type="text"
                      :label="texts.enterpriseData.rfc.label"
                      outlined
                      color="#000000"
                      dense
                      class="global-inputs mon-regular"
                      :placeholder="texts.enterpriseData.rfc.placeholder"
                      persistent-placeholder
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    cols="12"
                    md="12"
                  >
                    <div class="content-input-map">
                      <p
                        :style="bInputDisabled ? { color: 'rgba(0, 0, 0, 0.38)', opacity: '0.8' } : {}"
                        class="content-input-map-label mon-regular"
                      >
                        {{ texts.enterpriseData.address.label }}
                      </p>
                      <GmapAutocomplete
                        @place_changed="setPlace"
                        autocomplete=""
                        :value="enterpriseAddress"
                        :options="autocompleteOptions"
                      />
                    </div>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    cols="12"
                    md="12"
                  >
                    <div>
                      <v-text-field
                        v-model="enterpriseAddressOptional"
                        type="text"
                        :label="(selectLanguage == 'sp' ? 'Dirección específica' : 'Specific address')"
                        outlined
                        color="#000000"
                        dense
                        class="global-inputs mon-regular"
                        :placeholder="(selectLanguage == 'sp' ? 'Apt, suite, edificio, piso' : 'Apt, suite, building, floor')"
                        persistent-placeholder
                      ></v-text-field>
                    </div>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    cols="12"
                    md="6"
                  >
                    <v-select
                      v-model="enterpriseCountry"
                      :items="itemsCountries"
                      item-text="sName"
                      item-value="sCountryId"
                      :label="texts.enterpriseData.country.label"
                      outlined
                      color="#000000"
                      dense
                      class="global-inputs mon-regular"
                      :placeholder="texts.enterpriseData.country.placeholder"
                      @input="selecState"
                    >
                    </v-select>
                  </v-col>
                  <v-col
                    cols="12"
                    md="6"
                  >
                    <v-select
                      v-model="enterpriseState"
                      :items="itemsStates"
                      item-text="sName"
                      item-value="sStateId"
                      :label="texts.enterpriseData.state.label"
                      outlined
                      color="#000000"
                      dense
                      class="global-inputs mon-regular"
                      :placeholder="texts.enterpriseData.state.placeholder"
                      @input="selecState"
                    >
                    </v-select>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    cols="12"
                    md="6"
                  >
                    <v-text-field
                      v-model="enterpriseCity"
                      type="text"
                      :label="texts.enterpriseData.city.label"
                      outlined
                      color="#000000"
                      dense
                      class="global-inputs mon-regular"
                      :placeholder="texts.enterpriseData.city.placeholder"
                      persistent-placeholder
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    md="6"
                  >
                    <v-text-field
                      v-model="enterpriseZipCode"
                      type="text"
                      :label="texts.enterpriseData.zipCode.label"
                      outlined
                      color="#000000"
                      dense
                      class="global-inputs mon-regular"
                      :placeholder="texts.enterpriseData.zipCode.placeholder"
                      persistent-placeholder
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    cols="12"
                    md="12"
                  >
                    <v-textarea
                      v-model="enterpriseDescription"
                      class="global-inputs mon-regular"
                      :label="texts.enterpriseData.enterpriseDescription.label"
                      :placeholder="texts.enterpriseData.enterpriseDescription.placeholder"
                      outlined
                      color="#000000"
                      dense
                    ></v-textarea>
                  </v-col>
                </v-row>
              </div>
            </div>
          </v-col>
          <v-col
            cols="12"
            md="6"
          >
            <div>
              <GmapMap
                :center="center"
                :zoom="4"
                :options="mapOptions"
                style="width: 100%; height: 550px;"
              >
                <GmapMarker
                  :key="index"
                  v-for="(m, index) in markers"
                  :position="m.position"
                  @click="center = m.position"
                />
              </GmapMap>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <div
      v-if="isResponsive"
      class="pt-5 pb-5"
    >
      <v-btn
        v-if="switchEdit"
        @click="saveChanges"
        :loading="bLoading"
        class="button-save-changes mon-bold"
        elevation="0"
        style="width: 100%;"
      >
        {{ texts.profile.editForm.textButtonSaveChanges }}
      </v-btn>
      <v-btn
        v-if="switchEdit"
        class="button-edit mon-regular mt-5"
        elevation="0"
        @click="switchEdit = false;"
        style="width: 100%;"
      >
        {{ texts.profile.buttonCancel }}
      </v-btn>

      <v-btn
        v-if="!switchEdit"
        class="button-save-changes mon-bold pl-9 pr-9"
        elevation="0"
        @click="switchEdit = true;"
        style="width: 100%;"
      >
        {{ texts.profile.tab2.buttons.modify }}
        <v-icon
          class="ml-1"
          color="#283c4d"
          size="16px"
        >
          mdi-pencil
        </v-icon>
      </v-btn>
    </div>
    <div
      v-else
      class="display-flex align-items-center pt-5 pb-5"
    >
      <v-btn
        v-if="switchEdit"
        class="button-edit mon-regular pl-9 pr-9"
        elevation="0"
        @click="switchEdit = false;"
      >
        {{ texts.profile.buttonCancel }}
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn
        v-if="switchEdit"
        @click="saveChanges"
        :loading="bLoading"
        class="button-save-changes mon-bold"
        elevation="0"
      >
        {{ texts.profile.editForm.textButtonSaveChanges }}
      </v-btn>
      <v-btn
        v-if="!switchEdit"
        class="button-save-changes mon-bold pl-9 pr-9"
        elevation="0"
        @click="switchEdit = true;"
      >
        {{ texts.profile.tab2.buttons.modify }}
        <v-icon
          class="ml-1"
          color="#283c4d"
          size="16px"
        >
          mdi-pencil
        </v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProfileTabContentTwoLayout",
  props: {
    texts: {
      type: Object,
    },
    tab: {
      type: Number,
    },
  },
  data() {
    return {
      //VARIABLES
      epmtyText: "",
      bInputDisabled: true,
      bLoading: false,
      itemsStates: [],
      itemsCountries: [],
      enterpriseName: "",
      enterpriseAddressOptional: "",
      enterpriseAddress: "",
      enterpriseCountry: "",
      enterpriseState: "",
      enterpriseCountryName: "",
      enterpriseStateName: "",
      enterpriseCity: "",
      enterpriseZipCode: "",
      enterpriseEmail: "",
      enterprisePhone: "",
      enterpriseLicencePaca: "",
      enterpriseBlueBook: "",
      enterpriseRFC: "",
      enterpriseDescription: "",
      enterpriseLatitude: "",
      enterpriseLongitude: "",
      pais: "",
      estado: "",
      center: { lat: 37.09024, lng: -95.712891 },
      currentPlace: null,
      markers: [],
      mapOptions: {
        scrollwheel: false,
        mapTypeControl: false,
        zoomControl: true,
        disableDoubleClickZoom: true,
        keyboardShortcuts: false,
        streetViewControl: false,
        maxZoom: 10,
        minZoom: 3,
      },
      autocompleteOptions: {
        componentRestrictions: {
          country: ["us", "mx"],
        },
      },

      //switch edit
      switchEdit: false,
      isResponsive: false,
    };
  },
  beforeMount() {
    this.getEnterpriseData();
    this.validateTextEmpty();
    // this.getCountries();
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.addEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize: function () {
      if (window.innerWidth < 960) {
        this.isResponsive = true;
      } else {
        this.isResponsive = false;
      }
    },
    validateTextEmpty: function () {
      if (this.selectLanguage == "sp") {
        this.epmtyText = "Aún no hay información.";
      } else {
        this.epmtyText = "No infomation yet.";
      }
    },
    setPlace: function (place) {
      this.enterpriseCity = "";
      this.enterpriseZipCode = "";

      var dom = document.createElement("div");
      dom.innerHTML = place.adr_address;
      let postal_code_html = dom.getElementsByClassName("postal-code"),
        locality_html = dom.getElementsByClassName("locality");
      this.enterpriseZipCode = postal_code_html[0].innerHTML;
      this.enterpriseCity = locality_html[0].innerHTML;

      let split_name = place.formatted_address.split(",");
      let name_address = split_name[0] + "," + split_name[1];

      this.enterpriseAddress = name_address;
      this.currentPlace = place;
      this.addMarker();
    },
    addMarker: function () {
      this.markers = [];

      if (this.currentPlace) {
        this.enterpriseLatitude = this.currentPlace.geometry.location.lat();
        this.enterpriseLongitude = this.currentPlace.geometry.location.lng();

        const marker = {
          lat: this.currentPlace.geometry.location.lat(),
          lng: this.currentPlace.geometry.location.lng(),
        };
        this.markers.push({ position: marker });
        this.center = marker;
        this.currentPlace = null;
      }
    },
    getEnterpriseData: function () {
      const config = {
        headers: {
          Authorization: `Bearer ${this.$store.state.sToken}`,
        },
      };

      DB.get(
        `${URI}/api/v1/${this.selectLanguage}/customers/enterprises/${this.$store.state.sEnterpriseId}`,
        config
      )
        .then((response) => {
          let arr = response.data.results;
          this.enterpriseName = arr.sName;
          this.enterpriseAddress = arr.sLocationAddress;
          this.enterpriseAddressOptional = arr.sLocationOptionalAddress;
          this.enterpriseCity = arr.sLocationCity;
          this.enterpriseZipCode = arr.sLocationZipCode;
          this.enterpriseEmail = arr.sEmail;
          this.enterprisePhone = arr.sPhoneNumber;
          this.enterpriseLicencePaca = arr.sPACALicenseNumber;
          this.enterpriseBlueBook = arr.sBlueBookId;
          this.enterpriseRFC = arr.sTaxpayerId;
          this.enterpriseDescription = arr.sDescription;
          this.enterpriseLatitude = arr.dLocationLatitude;
          this.enterpriseLongitude = arr.dLocationLongitude;
          this.enterpriseCountry = arr.sCountryId;
          this.enterpriseState = arr.sLocationStateId;
          this.enterpriseCountryName = arr.sCountryName;
          this.enterpriseStateName = arr.sStateName;

          if (this.enterpriseLatitude || this.enterpriseLongitude) {
            const marker = {
              lat: arr.dLocationLatitude,
              lng: arr.dLocationLongitude,
            };
            this.markers.push({ position: marker });
            this.center = marker;
            this.currentPlace = null;
          }
          this.getCountries();
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    edit: function (val) {
      this.bInputDisabled = val;
    },
    soloNumbers: function (evt) {
      if (
        (evt.which != 8 && evt.which != 0 && evt.which < 48) ||
        evt.which > 57
      ) {
        evt.preventDefault();
      }
    },
    getCountries: function () {
      //post credentials and get access token from laravel backend
      DB.get(`${URI}/api/v1/${this.selectLanguage}/locations/countries`)
        .then((response) => {
          this.itemsCountries = response.data.results;
          // this.warehouseCountry = response.data.results[0].sCountryId;
          this.getStates();
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    selecState: function () {
      this.state = "";
      this.getStates();
    },
    getStates: function () {
      //post credentials and get access token from laravel backend
      DB.get(
        `${URI}/api/v1/${this.selectLanguage}/locations/countries/${this.enterpriseCountry}/states`
      )
        .then((response) => {
          this.itemsStates = response.data.results;
          // this.warehouseState = response.data.results[0].sStateId;
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    saveChanges: function () {
      this.bLoading = true;

      const payload = {
        sName: this.enterpriseName,
        sEmail: this.enterpriseEmail,
        sDescription: this.enterpriseDescription,
        sPhoneNumber: this.enterprisePhone,
        sLocationAddress: this.enterpriseAddress,
        sLocationOptionalAddress: this.enterpriseAddressOptional,
        sLocationCity: this.enterpriseCity,
        sLocationStateId: this.enterpriseState,
        sLocationZipCode: this.enterpriseZipCode,
        dLocationLatitude: this.enterpriseLatitude,
        dLocationLongitude: this.enterpriseLongitude,
        sPACALicenseNumber: this.enterpriseLicencePaca,
        sTaxpayerId: this.enterpriseRFC,
        sBlueBookId: this.enterpriseBlueBook,
      };

      const config = {
        headers: {
          Authorization: `Bearer ${this.$store.state.sToken}`,
        },
      };

      DB.put(
        `${URI}/api/v1/${this.selectLanguage}/customers/enterprises/${this.$store.state.sEnterpriseId}`,
        payload,
        config
      )
        .then((response) => {
          this.bLoading = false;

          this.switchEdit = false;
          this.mixSuccess(response.data.message);
        })
        .catch((error) => {
          this.bLoading = false;

          this.mixError(error.response.data.message, error.response.status);
        });
    },
  },
  computed: {
    selectLanguage: function () {
      return this.$store.state.language;
    },
  },
  watch: {
    selectLanguage: function () {
      if (this.selectLanguage) {
        // this.getCountries();
        this.getEnterpriseData();
      }
    },
    tab: function () {
      if (this.tab != 1) {
        this.switchEdit = false;
      }
    },
  },
};
</script>

<style>
.content-tab .global-inputs input:disabled,
.content-tab .global-inputs textarea:disabled,
.content-tab .global-inputs select:disabled {
  pointer-events: auto !important;
  cursor: no-drop !important;
  opacity: 0.9;
}

.content-tab .content-input-map input:disabled {
  pointer-events: auto !important;
  cursor: no-drop !important;
  opacity: 0.4;
}
</style>

<style scoped>
.text-header {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #283c4d;
  opacity: 1;
  margin-bottom: 0px;
}

.text-result {
  text-align: right;
  font-size: 14px;
  letter-spacing: 0px;
  color: #9aa6b1;
  opacity: 1;
  margin-bottom: 0px;
}

.card-content {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #e5e5e5;
  border-radius: 9px;
  opacity: 1;
}

.content-input-map-label {
  font-size: 10px;
  position: absolute;
  top: -8px;
  left: 12px;
  color: #00000090;
}

.content-input-map {
  position: relative;
}

.content-input-map input {
  width: 100%;
  height: 40px;
  background: #ffffff 0% 0% no-repeat padding-box !important;
  box-shadow: 0px 3px 6px #00000029 !important;
  border-radius: 9px !important;
  font-size: 14px;
  font-family: "monserrat-regular", sans-serif !important;
  padding: 0px 15px;
  letter-spacing: 0px;
  color: #000000;
}

.content-input-map input:focus-visible {
  outline: none;
}

.content-input-map input::-webkit-input-placeholder {
  color: #fff;
}

.content-input-map input:-moz-placeholder {
  color: #fff;
  opacity: 1;
}

.content-input-map input::-moz-placeholder {
  color: #fff;
  opacity: 1;
}

.content-input-map input:-ms-input-placeholder {
  color: #fff;
}

.v-input--is-disabled {
  opacity: 0.5;
  cursor: pointer !important;
  pointer-events: all !important;
}

.button-change-password {
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #283c4d;
  border-radius: 10px;
  opacity: 1;
  text-align: center;
  font-size: 14px;
  letter-spacing: 0px;
  color: #283c4d;
  text-transform: inherit;
  height: 40px !important;
  background-color: transparent !important;
}

.button-edit {
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #283c4d;
  border-radius: 10px;
  opacity: 1;
  text-align: center;
  font-size: 14px;
  letter-spacing: 0px;
  color: #283c4d;
  text-transform: inherit;
  height: 40px !important;
  background-color: transparent !important;
}

.button-save-changes {
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 10px;
  opacity: 1;
  text-align: center;
  font-size: 14px;
  letter-spacing: 0px;
  color: #283c4d;
  text-transform: inherit;
  height: 40px !important;
  background: transparent linear-gradient(254deg, #ffc556 0%, #fffc81 100%) 0%
    0% no-repeat padding-box;
}

.width-100 {
  width: 100%;
}

.text-circle {
  color: #ffffff;
  text-align: left;
  font-size: 100px;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  margin-bottom: 0px;
}

.circle-border {
  border: 5px solid #ffa67e;
  padding: 3px;
  border-radius: 100%;
  width: 250px;
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.circle-content {
  background: transparent linear-gradient(268deg, #ff8181 0%, #ffa77e 100%) 0%
    0% no-repeat padding-box;
  opacity: 1;
  border-radius: 100%;
  padding: 25px;
  width: 230px;
  height: 230px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.display-flex {
  display: flex;
}

.align-items {
  align-items: center;
}

.content-tab {
  padding: 40px 0px;
}

.border-top-line {
  border-top: 1px solid #cecece;
}

.border-bottom {
  border-bottom: 1px solid #cecece;
}

@media (max-width: 960px) {
  .center-in-small {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>